import * as React from "react";
import { Box } from "@mui/system";

import { SectionTile } from "_global/components/Containers";
import { Carousel } from "_global/components/Carousel";
import { SavedHomesContent } from "./SavedHomesContent";
import { ViewAllLink } from "./SavedHomesContent/SavedHomesContent.elements";
import { SavedHomesSectionHeader, CarouselControlsContainer } from "./SavedHomesSection.elements";
import { SavedHomesListingController } from "features/SavedHomesListing/SavedHomesListingController";

import { getActiveSavedHomes } from "features/SavedHomesListing/utils";
import type { TSavedHomesViewProps } from "features/SavedHomesListing/SavedHomesListingController";

export const SavedHomesSectionInner: React.FC<TSavedHomesViewProps> = ({
  isLoading,
  savedHomes,
}) => {
  const activeSavedHomes = React.useMemo(() => (
    getActiveSavedHomes(savedHomes || [])
  ), [savedHomes]);

  const hasSavedHomes = !!activeSavedHomes.length;

  const {
    shouldShowEmptyState,
    shouldShowLoadingState,
    shouldShowCarousel,
  } = React.useMemo(() => ({
    shouldShowEmptyState: !isLoading && !hasSavedHomes,
    shouldShowLoadingState: isLoading && !hasSavedHomes,
    shouldShowCarousel: hasSavedHomes,
  }), [isLoading, hasSavedHomes]);

  const maxWidth = React.useMemo(() => {
    if (!shouldShowCarousel) return "380px";
  
    // reduce carousel width if no likely overflow
    if (activeSavedHomes.length < 3) {
      const APPROX_CARD_WIDTH = 375;
      return `${activeSavedHomes.length * APPROX_CARD_WIDTH}px`;
    };

    // otherwise, use full width
    return "100%";
  }, [shouldShowEmptyState, activeSavedHomes]);

  return (
    <SectionTile.Container gap="28px" maxWidth={maxWidth} sx={{ overflow: "unset" }}>
      {!shouldShowCarousel ? (
        <>
          <SavedHomesSectionHeader />
          {shouldShowEmptyState && <SavedHomesContent.EmptyState />}
          {shouldShowLoadingState && <SavedHomesContent.LoadingState />}
        </>
      ) : (
        <Carousel.ContextWrapper>
          <Box display="flex" justifyContent="space-between" gap="8px">
            <SavedHomesSectionHeader />
            <CarouselControlsContainer>
              <ViewAllLink />
              <Carousel.ScrollButtons />
            </CarouselControlsContainer>
          </Box>
          <Carousel.ScrollContainer>
            <SavedHomesContent.CardList savedHomes={activeSavedHomes} />
          </Carousel.ScrollContainer>
        </Carousel.ContextWrapper>
      )}
    </SectionTile.Container>
  );
};

export const SavedHomesSection: React.FC = () => (
  <SavedHomesListingController component={SavedHomesSectionInner} />
);
