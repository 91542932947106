import type { TMenuItem } from "_global/components/Buttons/MenuButton";
import { EditOutlined, AddCircle, CameraAlt, SimCardDownload } from "@mui/icons-material";

export const PAHLISCH_SITE_URL = "https://wayfinder.pahlischhomes.com";

export const CONFIG_ID = "configId";
export const DESIGN_ROUTE = "/design";

export const MENU_ITEM_IDS = {
  saveNew: "save-as-new-design",
  overwriteCurrent: "overwrite-current-design",
  downloadSnapshot: "download-snapshot-photo",
  manageDesign: "manage-design",
} as const;

export const MENU_ITEMS: TMenuItem[] = [
  {
    id: MENU_ITEM_IDS.saveNew,
    icon: AddCircle,
    label: "Save as new design",
    onClick: () => undefined,
  },
  {
    id: MENU_ITEM_IDS.overwriteCurrent,
    icon: SimCardDownload,
    label: "Overwrite current design",
    onClick: () => undefined,
  },
  {
    id: MENU_ITEM_IDS.downloadSnapshot,
    icon: CameraAlt,
    label: "Download snapshot photo",
    onClick: () => undefined,
  },
];

export const MENU_ITEMS_DRAWER: TMenuItem[] = [
  {
    id: MENU_ITEM_IDS.manageDesign,
    icon: EditOutlined,
    label: "Edit design name",
    onClick: () => undefined,
  },
  ...MENU_ITEMS,
];
