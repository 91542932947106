import * as React from "react";
import { Info, CheckCircle, Warning } from "@mui/icons-material";
import type { SvgIconComponent } from "@mui/icons-material";

import {
  StyledBannerContainer,
  StyledTitleRow,
  StyledDescriptionRow,
  DismissButton,
  ActionButton,
} from "./Banner.elements";

import {
  EXIT_CLASS, EXIT_DURATION, DEFAULT_VARIANT,
} from "../constants";

import type { BannerVariants } from "../types";
import { Stack } from "@mui/system";

export type BannerActionProps = {
  label: string;
  onClick: () => void;
};

export type BannerProps = {
  id: string;
  icon?: SvgIconComponent;
  variant?: BannerVariants;
  title: string | React.ReactNode;
  description?: string | React.ReactNode;
  dismissable?: boolean;
  action?: BannerActionProps;
  duration?: number;
  onClose?: () => void;
};

const defaultIcons: Record<BannerVariants, SvgIconComponent> = {
  primary: Info,
  secondary: Info,
  info: Info,
  special: Info,
  success: CheckCircle,
  danger: Warning,
  warning: Warning,
};

export const Banner: React.FC<BannerProps> = ({
  icon,
  action,
  title,
  description,
  variant = DEFAULT_VARIANT,
  dismissable = true,
  onClose = () => undefined,
}) => {
  const [isClosing, setIsClosing] = React.useState<boolean>(false);

  const handleClose = () => setIsClosing(true);

  React.useEffect(() => {
    if (isClosing) {
      const timeout = setTimeout(() => {
        onClose();
        setIsClosing(false);
      }, EXIT_DURATION);
      return () => clearTimeout(timeout);
    }
  }, [isClosing]);

  const Icon = React.useMemo(() => (
    icon || defaultIcons[variant]
  ), [icon, variant]);

  const shouldAnimateExit = isClosing;

  return (
    <StyledBannerContainer
      className={shouldAnimateExit ? EXIT_CLASS : ""}
      variant={variant}
    >
      <StyledTitleRow>
        <Stack flexGrow={1} flexDirection="row" alignItems="center" gap="14px" flexWrap="wrap" rowGap="8px">
          <Stack display="flex" flexDirection="row" alignItems="center" gap="14px">
            <Icon className="main-icon" />
            {title}
          </Stack>
          {!!action && <ActionButton {...action} />}
        </Stack>
        {dismissable && <DismissButton onClick={handleClose} />}
      </StyledTitleRow>
      {!!description && (
        <StyledDescriptionRow>
          {description}
        </StyledDescriptionRow>
      )}
    </StyledBannerContainer>
  );
};
