import * as React from "react";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import { Box, styled } from "@mui/system";
import { StyledLink } from "./features/Auth/VerifyEmailPage/VerifyEmailPage.elements";
import {
  WAYFINDER_SUPPORT_BODY,
  WAYFINDER_SUPPORT_CTA,
  WAYFINDER_SUPPORT_DIVIDER,
  WAYFINDER_SUPPORT_EMAIL
} from "./features/Auth/constants";
import { AxiosError } from "axios";

const fontFamily = "Open Sans, Arial, sans-serif";

const StyledContainerOuter = styled("div")(({ theme }) => ({
  margin: "10px auto",
  padding: theme.spacing(10),
  backgroundColor: "#EFEFEF",
  [theme.breakpoints.down("sm")]: {
    margin: theme.spacing(0),
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.white,
  },
}));

const StyledContainerInner = styled("div")(({ theme }) => ({
  background: theme.palette.white,
  borderRadius: "16px",
  fontFamily,
  maxHeight: "620px",
  maxWidth: "824px",
  margin: "0 auto",
  padding: theme.spacing(8),
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(4, 2),
    background: theme.palette.grey[50],
  },
}));

const Title = styled(Box)(({ theme }) => ({
  fontSize: "60px",
  fontWeight: 600,
  lineHeight: "55px",
  color: theme.palette.primary.main,
}));

const Headline = styled(Box)(({ theme }) => ({
  margin: 0,
  fontSize: "32px",
  lineHeight: 1,
  fontWeight: 600,
  color: theme.palette.black,
}));

const Description = styled(Box)(({ theme }) => ({
  fontSize: "16px",
  margin: "0px 0px 16px 0px",
  fontWeight: 400,
  color: theme.palette.black,
}));
export const ErrorDefault = ({
  error: appError = null,
}: {
  error?: AxiosError | null;
}) => {
  const error = useRouteError();
  const userAgent = window.navigator.userAgent;
  const errorMessage = () => {

    if (isRouteErrorResponse(error)) {
      return `${error.status} ${error.statusText}`;
    } else if (error instanceof Error) {
      return error.message;
    } else if (typeof error === "string") {
      return error;
    } else {
      console.error(error);
      return "Unknown error";
    }
  };

  const supportIssueBody = `${WAYFINDER_SUPPORT_CTA}\r\n\r\n${WAYFINDER_SUPPORT_BODY}\r\n\r\n\r\n\r\n\r\n\r\n${WAYFINDER_SUPPORT_DIVIDER}\r\n${error ?? errorMessage()}\r\n${userAgent}`;

  return (
    <StyledContainerOuter>
      <StyledContainerInner>
        <Title component="h1">Oops.</Title>
        <Headline component="h2">
          Wayfinder is experiencing technical difficulties.
        </Headline>
        <br/>
        <Description component="p">
          We apologize for the inconvenience and appreciate your patience.
          If this error message persists upon page refresh,
          please report this issue to help us improve your Wayfinder experience.
        </Description>
        <pre>{Boolean(error) && errorMessage()}</pre>
        <pre>{appError && appError.message}</pre>
        <Box>
          <StyledLink
            href={`mailto:${WAYFINDER_SUPPORT_EMAIL}?subject=Wayfinder%20Issue%20Report&body=${encodeURIComponent(supportIssueBody)}`}
          >
            Report This Issue
          </StyledLink>
        </Box>
      </StyledContainerInner>
    </StyledContainerOuter>
  );
};
