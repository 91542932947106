import * as React from "react";
import { Stack, styled } from "@mui/system";
import { Button as BaseButton } from "@mui/base";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as ArrowRightIcon } from "_global/assets/icons/arrow-right-link-icon.svg";

import { EXIT_CLASS } from "../constants";
import { themeFonts } from "_global/theme/typography";
import type { BannerVariants } from "../types";
import type { BannerActionProps } from "./Banner";

const DISMISS_BUTTON_CLASS = "dismiss-btn";
const ACTION_BUTTON_CLASS = "action-btn";

const options = {
  shouldForwardProp: (prop: string) => !["variant"].includes(prop),
};

type StyledBannerProps = {
  variant: BannerVariants;
};

export const StyledBannerContainer = styled(Stack, options)<StyledBannerProps>(({
  theme,
  variant,
}) => {
  const { palette } = theme;

  const variantColors = React.useMemo(() => ({
    backgroundColor: palette.v2[variant].surface,
    borderColor: palette.v2[variant].border,
    "& .main-icon": {
      color: palette.v2[variant].main,
    },
  }), [palette, variant]);

  const fontStyles = themeFonts.textMdSemibold;

  const actionFontStyles = {
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: "0.14px",
    fontFamily: "Poppins, Arial, sans-serif",
    lineHeight: "20px",
  };

  const BUTTON_PADDING = 3;

  return {
    opacity: 1,
    padding: theme.spacing(1,2),
    gap: theme.spacing(1),
    minWidth: "100%",
    width: "100%",
    maxWidth: "100%",
    color: palette.v2.neutral[100],
    borderRadius: "0.5px",
    borderWidth: "1px",
    borderStyle: "solid",
    ...fontStyles,
    ...variantColors,
    "& button": {
      backgroundColor: "transparent",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      transition: "all .1s cubic-bezier(.4,0,.2,1)",
    },
    [`.${DISMISS_BUTTON_CLASS}`]: {
      borderRadius: "4px",
      alignSelf: "flex-start",
      border: ".5px solid transparent",
      color: palette.v2.neutral[70],
      marginRight: `-${BUTTON_PADDING}px`,
      padding: `${BUTTON_PADDING}px`,
      "&:hover": {
        borderColor: variantColors.backgroundColor,
        boxShadow: `0px 0px 2px 0px ${palette.v2.colorTextLink}`,
        color: palette.v2.colorTextLink,
      },
    },
    [`.${ACTION_BUTTON_CLASS}`]: {
      border: "none",
      color: palette.v2.colorTextLink,
      marginTop: "2px",
      ...actionFontStyles,
      lineHeight: "0px",
      ".arrow-right": {
        marginLeft: "6px",
        fill: palette.v2.colorTextLink,
        transition: "all .05s cubic-bezier(.4,0,.2,1)",
      },
      "&:hover": {
        textDecoration: "underline",
        textUnderlineOffset: "2px",
        ".arrow-right": {
          fill: palette.v2.colorTextLink,
          marginLeft: "7px",
        },
      },
    },
    [`&.${EXIT_CLASS}`]: {
      opacity: 0,
      transform: "translateX(100%)",
      transition: "all .2s cubic-bezier(.4,0,.2,1)",
    },
  };
});

export const StyledTitleRow = styled(Stack)(({ theme }) => ({
  width: "100%",
  display: "inline-flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  gap: theme.spacing(1),
}));

export const StyledDescriptionRow = styled(Stack)(({ theme }) => ({
  width: "100%",
  flexDirection: "row",
  alignItems: "center",
  color: theme.palette.v2.neutral[90],
  ...themeFonts.textMd,
}));

export const ActionButton: React.FC<BannerActionProps> = ({
  label,
  onClick,
}) => (
  <BaseButton
    className={ACTION_BUTTON_CLASS}
    onClick={onClick}
  >
    {label} <ArrowRightIcon className="arrow-right" height="14px" width="14px" />
  </BaseButton>
);

type ButtonProps = {
  onClick: () => void;
};

export const DismissButton: React.FC<ButtonProps> = ({ onClick }) => (
  <BaseButton
    aria-label="Dismiss this notification."
    className={DISMISS_BUTTON_CLASS}
    onClick={onClick}
  >
    <CloseIcon fontSize="small" />
  </BaseButton>
);
