import * as React from "react";
import { createPortal } from "react-dom";
import { useMessageEventListener } from "../hooks/useMessageEventListener";
import { useCreateVR360Cache } from "../hooks/useVR360Cache";
import { useToastRenderer } from "_global/components/Toast/hooks/useToastRenderer";
import { StudioHeader } from "../components/StudioHeader";
import {
  getVR360IframeUrl,
  handleGetConfigIdResults,
  logResultErrors,
} from "./utils";
import {
  APP_CONTAINER_ID,
  IFRAME_ID,
  INVALID_CONFIG_ID_TOAST,
} from "../constants";
import type { VR360ViewProps } from "../controllers/VR360DesignController";
import type { MutationCallbackOptions } from "_global/types";
import { Container } from "./VR360App.elements";
import { trackGAEvent } from "../../../utils/google-analytics/google-analytics";
import { useNavigate } from "react-router-dom";
import {CONFIG_ID, DESIGN_ROUTE} from "../components/constants";

const IS_DEBUG_MODE = false;

export type VR360ComponentProps = Omit<
  VR360ViewProps,
  "updateSavedDesign" | "createSavedDesign"
> & {
  onSaveNewDesign: () => void;
  onOverwriteSavedDesign: () => void;
  onDownloadSnapshot: () => void;
  onUpdateDesignName: (name: string, options?: MutationCallbackOptions) => void;
  onOpenEditDesignModal?: () => void;
};

export const VR360IframeApp: React.FC<VR360ViewProps> = (props) => {
  const {
    isAuthed,
    savedDesign,
    isInitialLoading,
    createSavedDesign,
    updateSavedDesign,
  } = props;

  const { showToast } = useToastRenderer();
  const navigate = useNavigate();
  useMessageEventListener({ debugMode: IS_DEBUG_MODE });

  const vr360ApiRef = React.useRef<ZondaVR360WebAppApi | null>(null);
  const [initVR360Url, setInitVR360Url] = React.useState<string | null>(null);
  const [didIframeInit, setDidIframeInit] = React.useState<boolean>(false);
  const vr360Api = didIframeInit ? vr360ApiRef.current : null;
  const savedConfigId = window.localStorage.getItem(CONFIG_ID);

  useCreateVR360Cache({ isAuthed, vr360Api, debugMode: IS_DEBUG_MODE });

  const onInit = (result: VR360Result) => {
    // TODO error handling on failure to initialize
    logResultErrors("onInit", result, IS_DEBUG_MODE);
    if (result.isReady) {
      setDidIframeInit(true);
    }
  };

  const iframeLoaded = React.useCallback(() => {
    const vr360WebApi = new ZondaVR360WebAppApi(
      document.getElementById(IFRAME_ID),
      onInit,
    );
    vr360ApiRef.current = vr360WebApi;
  }, []);

  React.useEffect(() => {
    // INITIALIZE IFRAME SRC URL FROM CONFIG_ID OR DEFAULT
    if (initVR360Url) return; // init once
    if (!isInitialLoading) {
      const savedDesignConfigId = savedConfigId ? savedConfigId : savedDesign?.configId;
      const initConfigId = savedDesignConfigId || "";

      const vr360IframeUrl = getVR360IframeUrl(initConfigId);
      setInitVR360Url(vr360IframeUrl);
      window.localStorage.removeItem(CONFIG_ID);
    }
  }, [savedDesign, isInitialLoading, initVR360Url]);

  React.useEffect(() => {
    if (!isAuthed) {
      window.localStorage.removeItem(CONFIG_ID);
    }
  }, [isAuthed]);

  const handleCreateSavedDesign = () => {
    if (!vr360Api) return;
    vr360Api.getConfigId((result: VR360Result) => {
      const { configId, isInvalid } = handleGetConfigIdResults(
        result,
        IS_DEBUG_MODE,
      );
      if (isInvalid) {
        return showToast(INVALID_CONFIG_ID_TOAST);
      }
      if (!isAuthed) {
        window.localStorage.setItem(CONFIG_ID, configId);
        return navigate("/login", { state: { from: DESIGN_ROUTE }});
      }
      createSavedDesign({ configId });
    });
    trackGAEvent({
      category: "Wayfinder",
      action: "saved_design",
    });
  };

  const handleUpdateSavedDesignConfigId = () => {
    if (!vr360Api || !isAuthed) return;
    vr360Api.getConfigId((result: VR360Result) => {
      const { configId, isInvalid } = handleGetConfigIdResults(
        result,
        IS_DEBUG_MODE,
      );
      if (isInvalid) {
        return showToast(INVALID_CONFIG_ID_TOAST);
      }
      updateSavedDesign({ configId });
    });
  };

  const handleUpdateDesignName = (
    name: string,
    options?: MutationCallbackOptions,
  ) => {
    if (name) updateSavedDesign({ name }, options);
  };

  const handleDownloadSnapshot = () => {
    if (!vr360Api) return;
    vr360Api.getConfigId((result: VR360Result) => {
      const { configId, isInvalid } = handleGetConfigIdResults(
          result,
          IS_DEBUG_MODE,
      );
      if (isInvalid) {
        return showToast(INVALID_CONFIG_ID_TOAST);
      }
      if (!isAuthed) {
        window.localStorage.setItem(CONFIG_ID, configId);
        return navigate("/login", { state: { from: DESIGN_ROUTE }});
      }
      vr360Api.downloadImage((result: VR360Result) => {
        logResultErrors("downloadImage", result, IS_DEBUG_MODE);
      });
    });
  };

  return (
    <>
      <StudioHeader
        {...props}
        onUpdateDesignName={handleUpdateDesignName}
        onSaveNewDesign={handleCreateSavedDesign}
        onDownloadSnapshot={handleDownloadSnapshot}
        onOverwriteSavedDesign={handleUpdateSavedDesignConfigId}
      />
      {initVR360Url &&
        createPortal(
          <Container>
            <iframe
              id={IFRAME_ID}
              style={{
                width: "100%",
                height: "100%",
              }}
              src={initVR360Url}
              onLoad={iframeLoaded}
            ></iframe>
          </Container>,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          document.getElementById(APP_CONTAINER_ID)!,
          "vr360",
        )}
    </>
  );
};
