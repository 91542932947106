import { LogoHeader } from "./components/LogoHeader";
import { SignUp } from "./components/SignUp";
import { LoginButton } from "./components/LoginButton";
import { ImageContainer } from "./components/ImageContainer";
import { FormContainer, PageContainer } from "./Login.elements";
import { useLogin } from "features/Auth/hooks/useLogin";
import { trackGAEvent } from "../../utils/google-analytics/google-analytics";
import { useLocation } from "react-router-dom";
import {DESIGN_ROUTE} from "../../features/DesignStudio/components/constants";

export const Login = () => {
  const location = useLocation();
  const isRequestFromDesign = location.state?.from && location.state?.from === DESIGN_ROUTE;
  const { handleLogin, handleSignUp } = isRequestFromDesign ? useLogin({ returnTo: DESIGN_ROUTE }) : useLogin();

  const login = () => {
    handleLogin();
    trackGAEvent({
      category: "Wayfinder",
      action: "login",
      label: "return_visit",
    });
  };

  const signup = () => {
    handleSignUp();
    trackGAEvent({
      category: "Wayfinder",
      action: "login",
      label: "new_signup",
    });
  };

  return (
    <PageContainer>
      {/* left column with backgroundImage & wavy border */}
      <ImageContainer />
      {/* right column with login form */}
      <FormContainer>
        <LogoHeader />
        <LoginButton onClick={login} />
        <SignUp onClickSignUp={signup} />
      </FormContainer>
    </PageContainer>
  );
};
