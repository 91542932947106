import type { ToastDataMap } from "_global/components/Toast/types";
import { EmailOutlined } from "@mui/icons-material";

export const getToastMessages = (email: string): ToastDataMap => ({
  sendResetEmailSuccess: {
    id: "reset-password-email-sent-success",
    message: `An email has been sent to ${email}.  Check your email to complete password reset.`,
    variant: "info",
    icon: EmailOutlined,
  },
  sendResetEmailError: {
    id: "reset-password-err",
    message: "An error occurred requesting a password reset.",
  },
});

export const WAYFINDER_SUPPORT_EMAIL = "wayfinder@pahlisch.com";
export const WAYFINDER_SUPPORT_CTA = "Please answer a few questions below so we can help make Wayfinder a better experience for you.";
export const WAYFINDER_SUPPORT_BODY = "What were you doing when you experienced this bug? Ex. creating an account, saving a home from the Pahlisch website, checking my Documents, using the Design Studio, etc. ";
export const WAYFINDER_SUPPORT_DIVIDER = "-----------------------------------------------------------------------------------------";
